<template>
  <div class='welcome'>
    <main class='welcome__main'>
      <div class='welcome__main__top' v-emoji>
        👋
      </div>
      <div class='welcome__main__bottom'>
        <h2 class='welcome__main__bottom__title'>Donblerへようこそ!</h2>
        <p class='welcome__main__bottom__description'>
          学習するコースを選択し、申し込みを完了しましょう。<br>
          申込時、下のプロモーションコードを入力すると1ヶ月無料で学習できます。
        </p>
        <p class='welcome__main__bottom__code'>
          {{ setCode }}
        </p>
        <p class='welcome__main__bottom__warn'>
          ※無料トライアル期間経過後より月額料金が発生します。
        </p>
        <ul class='welcome__main__bottom__course'>
          <li>
            <button-normal @emitClick='checkoutSubscription(2)'
            :loading='isLoading' class='welcome__main__bottom__course__front'>
              <p>フロントエンドコースを選択</p>
            </button-normal>
          </li>
          <li>
            <button-normal @emitClick='checkoutSubscription(3)'
            :loading='isLoading' class='welcome__main__bottom__course__back'>
              <p>バックエンドコースを選択</p>
            </button-normal>
          </li>
        </ul>
      </div>
    </main>
  </div>
</template>
<script>
import { loadStripe } from '@stripe/stripe-js';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';

export default {
  components: {
    ButtonNormal,
  },
  data() {
    return {
      isLoading: false,
      stripe: null,
      updateInfo: {
        first_course_select_status: null,
      },
    };
  },
  async created() {
    await this.execLoadCourseInfo();
    if (this.$store.state.study.courseInfo.id !== 1) {
      await this.execUpdateUser(1);
    }
  },
  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
    await this.execLoadCourseInfo();
  },
  methods: {
    loading() {
      this.isLoading = !this.isLoading;
    },
    judgeSphone() {
      return (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0) && navigator.userAgent.indexOf('Mobile') > 0;
    },
    async execLoadCourseInfo() {
      const result = await this.$store.dispatch('loadCourseInfo');
      if (result === 401) {
        this.$router.push({ name: 'Top' });
      } else if (!result) {
        this.$router.push({ name: 'Error' });
      }
    },
    async execUpdateUser(updateStatus) {
      const updateInfo = {};
      if (updateStatus !== null) updateInfo.first_course_select_status = updateStatus;
      const result = await this.$store.dispatch('updateUser', updateInfo);

      if (await result === 401) {
        this.$router.push({ name: 'Top' });
      } else if (await result) {
        // アップロード情報初期化
        this.updateInfo.first_course_select_status = null;
        if (this.judgeSphone()) {
          this.$router.push({ name: 'SpDashboard' });
        } else {
          this.$router.push({ name: 'Dashboard' });
        }
      } else {
        this.$router.push({ name: 'Error' });
      }
    },
    async checkoutSubscription(type) {
      try {
        this.loading();
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.$store.state.user.token}`;

        // type 2: フロント 3: バック 4: フルスタック
        const { data } = await this.$http.post('/api/auth/customer/checkout/subscription', {
          type,
        });

        this.stripe.redirectToCheckout({ sessionId: data.session.id });
      } catch (e) {
        this.loading();
      }
    },
  },
  computed: {
    judgeShowCourseInfo() {
      return this.$store.state.study.courseInfo.id !== '';
    },
    setCode() {
      return process.env.VUE_APP_COURSE_FREE_CODE;
    },
  },
  destroyed() {
    //
  },
};
</script>
<style lang="scss">
.welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  &__main {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    margin: 0 10px;
    max-width: 100%;
    &__top {
      display: flex;
      justify-content: center;
      img {
        width: 110px;
        height: 110px;
      }
    }
    &__bottom {
      margin-top: 20px;
      text-align: center;
      &__title {
        font-size: 24px;
      font-weight: 700;
      }
      &__description {
        font-size: 14px;
        margin-top: 10px;
        font-weight: 500;
      }
      &__code {
        background-color: $color-gray;
        border-radius: 4px;
        padding: 10px 0;
        font-size: 14px;
        font-weight: 600;
        color: $color-red;
      }
      &__warn {
        font-size: 10px;
        color: $color-dark-gray;
        margin-top: 10px;
      }
      &__course {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        li {
          margin-left: 15px;
        }
        li:first-child {
          margin-left: 0;
        }
        &__front {
          background-color: #FF984E;
          color: $color-white;
          border-radius: 8px;
          border: none;
        }
        &__back {
          background-color: #9462FF;
          color: $color-white;
          border-radius: 8px;
          border: none;
        }
        &__front:hover {
          background-color: lighten(#FF984E, 5%);
        }
        &__back:hover {
          background-color: lighten(#9462FF, 5%);
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
.welcome {
  &__main {
    padding: 20px;
    margin: 0 10px;
    &__top {
      img {
        width: 80px;
        height: 80px;
      }
    }
    &__bottom {
      &__title {
        font-size: 20px;
      }
      &__course {
        display: block;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        li {
          margin-left: 0;
        }
        li:first-child {
          margin-bottom: 20px;
        }
        &__front {
          background-color: #FF984E;
          color: $color-white;
          width: 100%;
          height: 40px;
        }
        &__back {
          background-color: #9462FF;
          color: $color-white;
          width: 100%;
          height: 40px;
        }
        &__front:hover {
          background-color: lighten(#FF984E, 5%);
        }
        &__back:hover {
          background-color: lighten(#9462FF, 5%);
        }
      }
    }
  }
}
}
</style>
